import React, { createContext, useState, useContext, useEffect } from "react";

import mg from "./mg.json";
import fr from "./fr.json"

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setlang] = useState("Français");
    const [bdlg,setbdlg] = useState(lang === "Français" ? fr : mg);
  const translations = bdlg;

  useEffect(() => {
    if (localStorage.getItem("langue")) {
      setlang(localStorage.getItem("langue"));
      setbdlg(localStorage.getItem("langue") === "Français" ? fr : mg)
    }
  }, [localStorage.getItem("langue")]);

  return (
    <LanguageContext.Provider value={{ translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
