import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Register_API } from "../../service/config";
import { toast } from "react-toastify";
import image from "../../assets/image/imgtest/image";
import VerifyEmail from "./VerifyEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";

export default function Inscription() {
  const [load, setLoad] = useState(false);
  const [showdp, setshowdp] = useState(false);
  const [verify, setverify] = useState(false);
  const navigate = useNavigate();
  const [util, setUtil] = useState({
    nom_util: "",
    prenom_util: "",
    email: "",
    tel_util: "",
    password: "",
    password_confirmation: "",
  });
  const [error, setError] = useState([]);
  const [success, setsuccess] = useState([]);
  //Gestoin des changement des input dans un formulaire
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setUtil({ ...util, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const apiErrors = {};
    if (util.nom_util === "") {
      setError({ ...error, nom_util: ["Le nom est requis"] });
      setLoad(false);
    } else if (util.prenom_util === "") {
      setLoad(false);
      setError({ ...error, prenom_util: ["Le prenom est requis"] });
    } else if (util.cin_util === "") {
      setLoad(false);
      setError({ ...error, cin_util: ["Le CIN est requis"] });
    } else if (util.password === "") {
      setLoad(false);
      setError({ ...error, password: ["Le mot de passe est requis"] });
    } else if (util.password_confirmation === "") {
      setLoad(false);
      setError({
        ...error,
        password_confirmation: [
          "Veuillez retapez votre mot de passe pour confirmer",
        ],
      });
    } else {
      try {
        await axios
          .post(Register_API, util)
          .then((response) => {
            console.log(response);
            setUtil({
              nom_util: "",
              prenom_util: "",
              email: "",
              tel_util: "",
              password: "",
              password_confirmation: "",
            });
            setverify(true);
            navigate("/verify", { state: { email: util.email } });
            toast.success("inscription réussi");
            console.log(util.email);
          })
          .catch((error) => {
            const msgErreur = error.response.data;
            const msg = msgErreur[0];
            console.log(error);
            if (msg) {
              apiErrors.nom_util = msg.nom_util;
              apiErrors.prenom_util = msg.prenom_util;
              apiErrors.email = msg.email;
              apiErrors.cin_util = msg.cin_util;
              apiErrors.tel_util = msg.tel_util;
              apiErrors.prenom_util = msg.prenom_util;
              apiErrors.password = msg.password;
              apiErrors.password_confirmation = msg.password_confirmation;
            } else if (error.response.data.line === 261) {
              toast.error("mail pas envoyer");
              navigate("/errorMail", { state: { email: util.email } });
            }
          });
        setError(apiErrors);
        setLoad(false);

        //TODO flash success
      } catch (error) {
        toast.warning("un probleme est survenu, verifiez votre connexion!");
        setLoad(false);
        console.log(error);
      }
    }
  };

  return (
    <div className="hero-sec container-fluid h-auto">
      <div className="row h-100 justify-content-center">
        <div className=" col-12 py-4" style={{ maxWidth: "800px" }}>
          <div
            className="card rounded-4 border-0 w-100 p-lg-4 p-2"
            style={{ zIndex: 8 }}
          >
            <div className="card-body">
              <div className="text-center">
                <div>
                  <img
                    alt=""
                    src={image.mtm}
                    style={{ width: "50px", height: "auto" }}
                  />
                </div>
                <h3 className="text-center pb-2">Création de compte</h3>
                <p className="text-center">
                  Inscrivez-vous gratuitement pour profiter pleinement des
                  offres du plateforme{" "}
                </p>
                <p className="text-start">(*) : champs obligatoire </p>
              </div>
              <form className="w-100" onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-12 col-lg-6">
                    <label className="form-label">Nom (*)</label>
                    <input
                      onChange={handleChange}
                      onKeyUp={(e) => {
                        if (e.target.value === "") {
                          setsuccess({ ...success, nom_util: [] });
                          setError({
                            ...error,
                            nom_util: ["le champs nom est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, nom_util: [] });
                          setsuccess({ ...success, nom_util: ["valider"] });
                        }
                      }}
                      type="text"
                      className={
                        "form-control " +
                        (error.nom_util?.length > 0 && " is-invalid")
                      }
                      value={util.nom_util}
                      name="nom_util"
                      placeholder="Nom"
                    />
                    {error.nom_util &&
                      error.nom_util.map((element) => (
                        <p key={element} className="invalid-feedback p-0 m-1">
                          * {element}
                        </p>
                      ))}
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <label className="form-label">Prenom (*)</label>
                    <input
                      onKeyUp={(e) => {
                        if (e.target.value === "") {
                          setsuccess({ ...success, prenom_util: [] });
                          setError({
                            ...error,
                            prenom_util: ["le champs prenom est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, prenom_util: [] });
                          setsuccess({ ...success, prenom_util: ["valider"] });
                        }
                      }}
                      onChange={handleChange}
                      type="text"
                      className={
                        "form-control " +
                        (error.prenom_util?.length > 0 && " is-invalid")
                      }
                      value={util.prenom_util}
                      name="prenom_util"
                      placeholder="Prenom"
                    />
                    {error.prenom_util &&
                      error.prenom_util.map((element) => (
                        <p key={element} className="invalid-feedback p-0 m-1">
                          * {element}
                        </p>
                      ))}
                  </div>
                </div>
                <div className="my-3">
                  <label className="form-label">
                    Carte d'Identité Nationale{" "}
                  </label>
                  <InputMask
                    onKeyUp={(e) => {
                      console.log(e.target.value);
                      if (e.target.value === "") {
                        setError({
                          ...error,
                          cin_util: ["le champs CIN est requis"],
                        });
                      } else if (e.target.value !== "") {
                        setError({ ...error, cin_util: [] });
                      }
                    }}
                    onChange={handleChange}
                    mask="999 999 999 999"
                    type="text"
                    className={
                      "form-control " +
                      (error.cin_util?.length > 0 && " is-invalid")
                    }
                    value={util.cin_util}
                    name="cin_util"
                    placeholder="EX: 201 031 000 000"
                  />

                  {error.cin_util &&
                    error.cin_util.map((element) => (
                      <p key={element} className="invalid-feedback p-0 m-1">
                        * {element}
                      </p>
                    ))}
                </div>
                <div className="my-3">
                  <label className="form-label">Adresse email</label>
                  <input
                    onKeyUp={(e) => {
                      if (e.target.value !== "") {
                        setError({ ...error, email: [] });
                      }
                    }}
                    onChange={handleChange}
                    type="text"
                    className={
                      "form-control " +
                      (error.email?.length > 0 && " is-invalid")
                    }
                    value={util.email}
                    name="email"
                    placeholder="Email Adress"
                  />
                  {error.email &&
                    error.email.map((element) => (
                      <p key={element} className="invalid-feedback p-0 m-1">
                        * {element}
                      </p>
                    ))}
                </div>
                <div className="my-3">
                  <label className="form-label">Telephone (*)</label>
                  <InputMask
                   
                    onChange={handleChange}
                    mask="+261 39 99 999 99"
                    type="text"
                    className={
                      "form-control " +
                      (error.tel_util?.length > 0 && " is-invalid")
                    }
                    value={util.tel_util}
                    name="tel_util"
                    placeholder="Telephone"
                  />

                  {error.tel_util &&
                    error.tel_util.map((element) => (
                      <p key={element} className="invalid-feedback p-0 m-1">
                        * {element}
                      </p>
                    ))}
                </div>
                <div className="my-3">
                  <label className="form-label">Mot de passe (*)</label>
                  <div className="input-group ">
                    <input
                      onKeyUp={(e) => {
                        if (e.target.value === "") {
                          setError({
                            ...error,
                            password: ["le champs mot de passe est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, password: [] });
                        }
                      }}
                      onChange={handleChange}
                      type={showdp ? "text" : "password"}
                      name="password"
                      value={util.password}
                      className={
                        "form-control " +
                        (error.password?.length > 0 && " is-invalid")
                      }
                      placeholder="Mot de passe"
                    />
                    <div
                      className="input-group-append"
                      onClick={() => setshowdp(!showdp)}
                    >
                      <span className="input-group-text h-100">
                        <FontAwesomeIcon icon={showdp ? faEye : faEyeSlash} />
                      </span>
                    </div>
                    {error.password &&
                      error.password.map((element) => (
                        <p key={element} className="invalid-feedback p-0 m-1">
                          * {element}
                        </p>
                      ))}
                  </div>
                </div>
                <div className="my-3">
                  <label className="form-label">
                    Mot de passe de confirmation (*)
                  </label>
                  <input
                    onKeyUp={(e) => {
                      if (e.target.value === "") {
                        setError({
                          ...error,
                          password_confirmation: ["ce champs est requis"],
                        });
                      } else if (e.target.value !== "") {
                        setError({ ...error, password_confirmation: [] });
                      }
                    }}
                    onChange={handleChange}
                    type="password"
                    name="password_confirmation"
                    value={util.password_confirmation}
                    className={
                      "form-control " +
                      (error.password_confirmation?.length > 0 && " is-invalid")
                    }
                    placeholder="Confirmation du passe"
                  />
                  {error.password_confirmation && (
                    <p className="invalid-feedback p-0 m-1">
                      * {error.password_confirmation}
                    </p>
                  )}
                </div>
                <div className="d-flex gap-2 justify-content-center my-3">
                  <button
                    type="button"
                    className={`btn btn-sm btn-secondary py-2 px-3 rounded-5  ${
                      load ? "disabled" : ""
                    }`}
                    onClick={() => navigate("/")}
                  >
                    Retour à l'accueil
                  </button>
                  <button
                    type="submit"
                    className={
                      "btn btn-sm btn-primary  py-2 px-3 rounded-5 " +
                      (load && "disabled")
                    }
                  >
                    Créer un compte
                  </button>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <p className="text-muted">
                    Vous avez déjà un compte,{" "}
                    <Link
                      to="/login"
                      className="text-decoration-none text-primary"
                    >
                      Connectez-vous
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
