/* eslint-disable no-console */
import React from "react";
import { useState } from "react";
import { Navigate, Routes, useLocation, useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import Accueil from "./components/Accueil/Accueil";
import Login from "./components/Authentification/Login";
import Contact from "./components/Contact/Contact";
import Exercices from "./components/Exercices/Exercices";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import AccueilModule from "./components/modules/Accueil_module";
import Commentaires from "./components/modules/Commentaires";
import DetailsModule from "./components/modules/Details_module";
import ListModule from "./components/modules/List_module";
import Inscription from "./components/Authentification/Inscription";
import { ToastContainer } from "react-toastify";
import "./App.css";
import GererModule from "./components/Admin/module/GererModule";
import GererLesson from "./components/Admin/lessons/GererLesson";
import AuthApi from "./service/auth/AuthApi";
import ProtectedRoute from "./service/auth/PrivateRoute";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import isconnected from "./service/isconnected";
import RecherchePage from "./components/recherchepage/RecherchePage";
import BoiteNotification from "./components/Notification/BoiteNotification";
import Pointers from "./components/Admin/pointer/Pointers";
import Pointer from "./components/Admin/pointer/Pointer";
import DashboardV2 from "./components/Admin/DashboardV2";
import VerifyEmail from "./components/Authentification/VerifyEmail";
import Categories from "./components/Admin/categorie/Categories";
import NotFound from "./components/404/NotFound";
import Compte from "./components/Compte/Grand Public/Compte";
import Evolution from "./components/Compte/Grand Public/Evolution";
import GererLessonFichier from "./components/Admin/lessons/GererLessonFichier";
import GererQuestionnaire from "./components/Admin/questionnaire/GererQuestionnaire";
import GererQuestionnaireFichier from "./components/Admin/questionnaire/GererQuestionnaireFichier";
import axios from "axios";
import { LESSON_API, moduleByLesson } from "./service/config";
import ModuleLesson from "./components/Admin/module/ModuleLesson";
import ListeReponse from "./components/Admin/questionnaire/ListeReponse";
import ListeReponseFich from "./components/Admin/questionnaire/ListeReponseFich";
import Lessons from "./components/Lessons/Lessons";
import Aleatoire from "./components/Exercices/Aleatoire";
import Error from "./components/Authentification/ErrorMail";
import Comment_Les from "./components/Lessons/Comment_Les";
import ExoModule from "./components/Exercices/ExoModule";
import LessonContext from "./service/context/LessonContext";
import GererSens from "./components/Admin/Sensibilisation/GererSens";
import GererActu from "./components/Admin/Actualité/GererActu";
import GererTexteLois from "./components/Admin/TexteLois/GererTexteLois";
import GererTexteLoisFichier from "./components/Admin/TexteLois/GererTexteLoisFichier";
import Text_Lois from "./components/Texte et lois/Text_Lois";
import TousActu from "./components/Accueil/section/TousActu";
import Details_actu from "./components/Accueil/section/Details_actu";
import Details_sens from "./components/Accueil/section/Details_sens";
import TousSens from "./components/Accueil/section/TousSens";
import List_Text_Lois from "./components/Texte et lois/List_Text_Lois";
import AddModifActu from "./components/Admin/Actualité/AddModifActu";
import AjoutModifLesson from "./components/Admin/lessons/AjoutModifLesson";
import Testyyy from "./Testyyy";
import socket from "./service/Socket_Connection";
import NotificationAdmin from "./components/Notification/NotificationAdmin";
import ReponseNotifAdmin from "./components/Notification/ReponseNotifAdmin";
import Notif from "./service/context/NotifContext";
import Organisme from "./components/Admin/Organisme/Organisme";
import Auto_Ecole from "./components/Admin/Auto-ecole/Auto_Ecole";
import AccueilORG from "./components/Admin/Organisme/AccueilORG";
import MembreOrganisme from "./components/Admin/Organisme/MembreOrganisme";
import Candidats from "./components/Admin/Auto-ecole/Candidat";
import Apprenants from "./components/Admin/Auto-ecole/Apprenants";
import Examens_Avec_Att from "./components/Admin/Organisme/Examens_Avec_Att";
import Resultats from "./components/Admin/Organisme/Resultats";
import GestionSMS from "./components/Admin/Organisme/GestionSMS";
import ModuleExercice from "./components/Admin/module/ModuleExercice";
import AccueilStat from "./components/Admin/Organisme/Statistique de suivi/AccueilStat";
import StatGeneral from "./components/Admin/Organisme/Statistique de suivi/StatGeneral";
import StatModule from "./components/Admin/Organisme/Statistique de suivi/StatModule";
import StatAutoecole from "./components/Admin/Organisme/Statistique de suivi/StatAutoecole";
import StatCandidat from "./components/Admin/Organisme/Statistique de suivi/StatCandidat";
import Cartographie from "./components/Admin/Organisme/Cartographie";
import ScrollToTopOnMount from "./service/remonter";
import Comment_Mod from "./components/Admin/module/Comment_Mod";
import ExamenType from "./components/Admin/Examen/ExamenType";
import jwtDecode from "jwt-decode";
import ExoCategorie from "./components/Exercices/ExoCategorie";
import AccueilExercice from "./components/Exercices/AccueilExercice";
import Gestion from "./components/Admin/Utilisateurs/Gestion";
import GererChapitre from "./components/Admin/lessons/GererChapitre";
import GererParamNotes from "./components/Admin/GererExamen/GererParamNotes";
import Historique from "./components/Admin/Historique/Historique";
import AccountDeactivated from "./components/404/AccountDeactivated";
import { profile } from "./service/CallApi/getProfile";
import { useDispatch } from "react-redux";
import { LanguageProvider } from "./service/langue/LanguageProvider";

AuthApi.setup();
export default function App() {
  const [test, settest] = useState("Français");
  const [user, setuser] = useState({});
  const [Time, setTime] = useState(0);
  const [notif, setnotif] = useState("");
  const { pathname } = useLocation();
  const [rech, setrech] = useState("");
  const [load_categorie, setload_categorie] = useState("");
  const [categ, setcateg] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-60px";
    }
    prevScrollpos = currentScrollPos;
  };
  const [show, setShow] = useState(true);
  const [actif, setactif] = useState(false);
  let dataLes = [];
  const [lessons, setLessons] = useState([]);

  const Wrapper = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return children;
  };

  // const getpourx =async ()=>{
  //  await axios.get('http://127.0.0.1:8000/api/pourcentagebyuser').then((rep)=>{
  //     console.log(rep)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }

  useEffect(() => {
    AuthApi.setup();
    // getpourx()
    socket.auth = {
      username:
        isconnected.user().email === undefined
          ? "olo"
          : isconnected.user().email !== undefined &&
            isconnected.user().type_util === "SuperAdmin"
          ? isconnected.user().type_util
          : isconnected.user().email,
    };
    socket.connect();
    setactif(isconnected.Authenticated());
  }, [pathname]);
  useEffect(() => {
    profile().then((data) => {
      setuser(data);

      console.log(data);
      let status = data?.status_util;
      let url = pathname.split("/");
      if (url[1]) {
        if (url[1] == "exerciceBycateg" || url[1] == "exercice") {
          if (status == "Desactiver") {
            navigate("/verifyAccountUser");
          }
        }
      }
    });
  }, [pathname]);
  useEffect(() => {
    console.log(localStorage.getItem("langue"));
  }, [localStorage.getItem("langue")]);

  return (
    <LanguageProvider>
      <div className="section1 h-auto">
        <ScrollToTopOnMount />
        {
          <Routes>
            <Route element={<ProtectedRoute.AdminRoute setactif={setactif} />}>
              <Route path="/admin/organisme" element={<Organisme />} />
              <Route
                path="/admin/module exercice/:id"
                element={<ModuleExercice />}
              />
              <Route path="/admin/GererNote" element={<GererParamNotes />} />
              <Route
                path="/admin/module lesson/:id"
                element={<ModuleLesson />}
              />
              <Route
                path="/admin/mes notifications"
                element={<NotificationAdmin />}
              />
              <Route
                path="/admin/mes notification/reply"
                element={<ReponseNotifAdmin />}
              />

              <Route path="/admin/categorie" element={<Categories />} />
              <Route
                path="/admin/gerer_questionnaire"
                element={<GererQuestionnaire />}
              />
              <Route
                path="/admin/reponses/:idques"
                element={<ListeReponse />}
              />
              <Route
                path="/admin/reponsesFichier/:idques"
                element={<ListeReponseFich />}
              />
              <Route
                path="/admin/fichier_ques"
                element={<GererQuestionnaireFichier />}
              />
              <Route
                path="/admin/gerer_lesson/:id"
                element={<AjoutModifLesson />}
              />
              <Route path="/admin/gerer_lesson" element={<GererLesson />} />
              <Route path="/admin/profile" element={<Compte />} />
              <Route
                path="/admin/leçon/chapter/:id"
                element={<GererChapitre />}
              />
              <Route
                path="/admin/fichier_les/:id"
                element={<GererLessonFichier />}
              />
              <Route
                path="/admin/gerer_textelois"
                element={<GererTexteLois />}
              />
              <Route
                path="/admin/fichier_texte/:id"
                element={<GererTexteLoisFichier />}
              />
              <Route
                path="/admin/gerer_sensibilisation"
                element={<GererSens />}
              />
              <Route
                path="/admin/gestion des utilisateurs"
                element={<Gestion />}
              />
              <Route
                path="/admin/gerer_actualite/:id"
                element={<AddModifActu />}
              />
              <Route path="/admin/historique" element={<Historique />} />
              <Route path="/admin/gerer_actualite" element={<GererActu />} />
              <Route path="/admin/gerer_module" element={<GererModule />} />
              <Route
                path="/admin/gerer_module/comment/:id"
                element={<Comment_Mod />}
              />
              <Route path="/admin/gerer_pointer" element={<Pointers />} />
              <Route path="/admin" element={<DashboardV2 />} />
              <Route path="/admin/pointer/:id" element={<Pointer />} />
              <Route path="/admin/exam_type" element={<ExamenType />} />
            </Route>

            <Route element={<ProtectedRoute.PrivateRoute />}>
              <Route
                element={
                  <div
                    className="d-flex flex-column justify-content-between"
                    style={{ minHeight: "100vh" }}
                  >
                    <Header
                      notlog={!actif}
                      user={user}
                      setrech={setrech}
                      setload_categorie={setload_categorie}
                      settest={settest}
                      setactif={setactif}
                      setcateg={setcateg}
                    />{" "}
                    <Footer />
                  </div>
                }
              >
                <Route path="/notification" element={<BoiteNotification />} />
                <Route path="/contact" element={<Contact />} />
                <Route
                  path="/rechercher/:id"
                  element={<RecherchePage rech={rech} />}
                />
                <Route
                  path="/verifyAccountUser"
                  element={<AccountDeactivated />}
                />
                <Route path="/commentaires/:id" element={<Commentaires />} />
                <Route
                  path="/commentaires lesson/:id"
                  element={<Comment_Les />}
                />
                <Route path="/exercice/:id" element={<ExoModule />} />
                <Route path="/exerciceBycateg/:id" element={<ExoCategorie />} />
                <Route
                  path="/acc_exo"
                  element={<AccueilExercice test={test} categorie={categ} />}
                />
                <Route path="/exercices" element={<Exercices />} />
                <Route path="/aleatoire_exercice" element={<Aleatoire />} />
                <Route
                  path="/details/:id"
                  lesson={lessons}
                  element={<DetailsModule />}
                />
                <Route
                  path="/modules/:id"
                  element={
                    <ListModule load_categorie={load_categorie} test={test} />
                  }
                />

                <Route path="/lessons/:id" element={<Lessons />} />

                <Route path="/modules" element={<AccueilModule />} />

                <Route path="/profile" element={<Compte />} />
                <Route path="/evolution" element={<Evolution />} />
              </Route>
            </Route>

            <Route element={<ProtectedRoute.Public />}>
              <Route
                element={
                  <div
                    className="d-flex flex-column justify-content-between"
                    style={{ minHeight: "100vh" }}
                  >
                    <Header
                      notlog={!actif}
                      user={user}
                      setrech={setrech}
                      setload_categorie={setload_categorie}
                      settest={settest}
                      setactif={setactif}
                      setcateg={setcateg}
                    />{" "}
                    <Footer notlog={!actif} />
                  </div>
                }
              >
                <Route path="/texte et lois/:id" element={<Text_Lois />} />
                <Route path="/lois et Type/:id" element={<List_Text_Lois />} />

                <Route
                  path="/détails actualités/:id"
                  element={<Details_actu />}
                />
                <Route
                  path="/détails sensibilisation/:id"
                  element={<Details_sens />}
                />
                <Route
                  path="/tous les message de sensibilisation"
                  element={<TousSens />}
                />
                <Route
                  exact
                  path="/tous les actualités"
                  element={<TousActu />}
                />
                <Route
                  exact
                  path="/"
                  element={
                    <Accueil test={test} categorie={categ} funcNav={setShow} />
                  }
                />
              </Route>
            </Route>

            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/errorMail" element={<Error />} />
            <Route path="/inscription" element={<Inscription />} />

            <Route path="/login" element={<Login funcNav={setShow} />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/test" element={<Testyyy />} /> */}

            <Route element={<ProtectedRoute.OrganismeRoute />}>
              <Route path="/organisme/profile" element={<Compte />} />
              <Route path="/organisme/auto-école" element={<Auto_Ecole />} />
              <Route
                path="/organisme/statistique de suivi"
                element={<AccueilStat />}
              />
              <Route path="/organisme/statgen" element={<StatGeneral />} />
              <Route path="/organisme/statmodule" element={<StatModule />} />
              <Route
                path="/organisme/statautoecole"
                element={<StatAutoecole />}
              />
              <Route path="/organisme/gerer" element={<Organisme />} />
              <Route
                path="/organisme/cartographie"
                element={<Cartographie />}
              />
              <Route
                path="/organisme/statcandidat"
                element={<StatCandidat />}
              />
              <Route path="/organisme/accueil" element={<AccueilORG />} />
              <Route path="/organisme/membres" element={<MembreOrganisme />} />
              <Route path="/organisme/candidats" element={<Candidats />} />
              <Route path="/organisme/apprenants" element={<Apprenants />} />
              <Route path="/organisme/examens" element={<Examens_Avec_Att />} />
              <Route path="/organisme/resultats" element={<Resultats />} />
              <Route path="/organisme/gestion sms" element={<GestionSMS />} />
              <Route path="/organisme/cursus" element={<Pointers />} />
              <Route path="/organisme/cursus/:id" element={<Pointer />} />
            </Route>
          </Routes>
        }
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </LanguageProvider>
  );
}
