import axios from "axios";
import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Bounce } from "react-awesome-reveal";
import {
  CategorieByModule_API,
  CATEGORIE_API,
  file_url,
  listallmodule,
  MODULE_API,
} from "../../service/config";
import { useLanguage } from "../../service/langue/LanguageProvider";

export default function Accueil_module() {
  const {translations} = useLanguage()
  function truncate(str) {
    if (str.length < 50) {
      return str;
    } else {
      return str.substring(0, 100) + "...";
    }
  
  }
  const styleCard = {
    position: "relative",
    zIndex: "20",
  };
  const styleBulle = {
    position: "relative",
    top: "-90px",
    zIndex: "56",
  };
  let dataMod = [];
  const [load, setLoad] = useState(true);
  const [loadcateg, setloadcateg] = useState(true);
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" }
  ]);
  const [modules, setModules] = useState([]);
  const [categorie, SetCategorie] = useState([]);
  const getCategorie = async () => {
    setloadcateg(true);
    try {
      await axios
        .get(CATEGORIE_API)
        .then((reponse) => {
          SetCategorie(reponse.data.data);
          console.log(reponse.data);
          setloadcateg(false);
        
        })
        .catch((error) => {
          console.log(error);
          toast.error("verifier votre connexion");
        });
    } catch (error) {
      console.log(error);
      toast.error("erreur interne!");
    }
  };


  const getModules = async () => {
    setLoad(true);
    dataMod = [];
    try {
      await axios
        .get(listallmodule+localStorage.getItem("langue"))
        .then((response) => {
          console.log(response);
          const data = response.data.data;
          setModules(data)
         setLoad(false)
         
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategorie()
    getModules();  
  },[]);

  return (
    <div className="pb-5">
      <div className="border bg-white container-fluid container-lg py-4 my-3 rounded-3 ">
        <div className="titre text-center">
          <p className="h1">{translations?.categories}</p>
        </div>
        <div className="titre text-center ">
          <p className="h4">
          {translations?.categoryQuestion}
          </p>
        </div>
        <div className="row justify-content-center pb-3">
          <div className="col-lg-6 col-12">
            <div className="row categorie gap-2 justify-content-center mt-2">
              {loadcateg ? (
                <div className="col-12 d-flex justify-content-center">
                  <Bars
                    height="80"
                    width="80"
                    color="#22577A"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : ( categorie.length > 0 ?
                categorie.map((el, index) => (
                  <div className="col-auto px-0">
                    <Bounce delay={Number(`${index + 1}00`)} triggerOnce>
                      <Link
                        to={`/modules/${el.categ}`}
                        className="text-decoration-none text-dark d-block"
                      >
                        <div className="bg-secondary cat fs-3 avatar avatar-xm text-white avatar-rounded">
                          {el.categ}
                        </div>
                      </Link>
                    </Bounce>
                  </div>
                )) : <div className="display-6 text-center">Aucun catégorie disponible</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg container-fluid rounded-3 my-3 ">
        <div className="row">
       

          {load ? (
            <>
              {chiffre.map((io, i) => (
                <div
                  key={i}
                  className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-3"
                >
                  <div
                    className="card effet rounded-4 placeholder"
                    style={styleCard}
                  >
                    <div
                      className="card-header placeholder rounded-top-4 bg-azure position-relative p-5 d-flex justify-content-center w-100"
                      style={{
                        position: "relative",
                        zIndex: "20",
                        backgroundSize: "cover",
                      }}
                    >
                      <span
                        className="avatar avatar-md bg-azure text-white mb-3 placeholder avatar-rounded"
                        style={styleBulle}
                      >
                        A'
                      </span>
                    </div>
                    <div className="card-body placeholder">
                      <Link to="/details/2">
                        <h5 className="card-title text-center placeholder">
                          ishdhsufhsfuisd
                        </h5>
                      </Link>
                      <p className="card-text fw-light text-center fs-4 pb-4 placeholder">
                        Lorem nfuiwhweurgwh uy hh uegwe yugewyu gweu gweuwg
                        ueygw uygweuygeywu
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : modules.length >0 ?modules.map((element, index) => (
            <div
              key={index}
              className="col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch py-3"
            >
              <div className="card effet rounded-4 w-100">
                <div
                  className="card-header rounded-top-4 bg-azure position-relative p-5 d-flex justify-content-center w-100"
                  style={{
                    backgroundImage: `url(${file_url}/${element.img_mod})`,
                    backgroundSize: "cover",
                  }}
                >
                  <p className="p-3"></p>
                </div>
                <div className="card-body">
                  <Link
                    to={`/details/${element.id_mod}`}
                    state={{ data: element}}
                  >
                    <h5 className="card-title">{element.titre_mod}</h5>
                  </Link>
                  <div className="card-subtitle d-flex flex-row flex-wrap gap-1">{element.categories.map((el,ino)=><span key={ino} className="badge bg-cyan px-3">{el.categ}</span>)}<span className="border fst-italic px-2 rounded-3">{element.lang_mod}</span></div>
                  <p className="card-text fw-light fs-4 pb-4">
                    {truncate(element.desc_mod)}
                  </p>
                </div>
              </div>
            </div>
          )): <div className="display-6 text-center py-5">Aucun modules disponible</div>}
        </div>
      </div>
    </div>
  );
}
