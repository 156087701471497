import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./section1.css";
import { Bounce, JackInTheBox } from "react-awesome-reveal";
import { CATEGORIE_API } from "../../../service/config";
import axios from "axios";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import useIntersectionObserver from "../../../outils/ObserverApparitionSection";
import { useLanguage } from "../../../service/langue/LanguageProvider";

export default function Section2() {

const {translations} = useLanguage()
  const {dataRedux,load,sectionRef}  = useIntersectionObserver(CATEGORIE_API,'categ','addcateg','data');
  const sortByProperty = (array, property) =>
    [...array].sort((a, b) =>
      typeof a[property] === 'string'
        ? a[property].localeCompare(b[property])
        : a[property] - b[property]
    );
  const categorieSort = sortByProperty(dataRedux, "categ");
  // const getCategorie = async () => {
  //  if (dataRedux.length == 0) {
  //   setload(true);
  //   try {
  //     await axios
  //       .get(CATEGORIE_API)
  //       .then((reponse) => {
  //         SetCategorie(reponse.data.data);
  //         console.log(reponse.data);
  //         dispatch({type:'addcateg', payload:reponse.data.data})
  //         setload(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast.error("verifier votre connexion");
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("erreur interne!");
  //   }
  //  }else {
  //   setload(false)
  //  }
  // };
  // useEffect(() => {
  //   getCategorie();
  // }, []);
  return (
    <div className="section1 pt-3 pb-5 my-4">
      <div className="container">
        <div ref={sectionRef} className="titre text-center m-4">
        <JackInTheBox> <p className="h1">{translations?.categories}</p></JackInTheBox> 
        </div>
        <div className="titre text-center m-4">
          <p className="h4">
          {translations?.categoryQuestion}
          </p>
        </div>
        <div className="row justify-content-center ">
          <div className="col-lg-6 col-12">
            <div className="row categorie gap-2 justify-content-center ">
              {load ? (
                <div className="col-12 d-flex justify-content-center">
                  <Bars
                    height="80"
                    width="80"
                    color="#22577A"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (dataRedux.length>0 ?
                categorieSort.map((el, index) => (
                  <div key={index} className="col-auto px-0">
                    <Bounce delay={Number(`${index + 1}00`)} triggerOnce>
                      <Link
                        to={`/modules/${el.categ}`}
                        className="text-decoration-none text-dark d-block"
                      >
                        <div className="bg-secondary cat fs-3 avatar avatar-xm text-white avatar-rounded">
                          {el.categ}
                        </div>
                      </Link>
                    </Bounce>
                  </div>
                )) : <div className="display-6 text-center">Aucun catégorie disponible</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="recommandation"></div>
    </div>
  );
}
