
import React from "react";
import "../section/section1.css";
import imagesec from "../../../assets/image/cover.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faFileContract,
  faFilePrescription,
  faFileSignature,
  faHand,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import image from "../../../assets/image/imgtest/image";
import ScrollToTop from "react-scroll-to-top";
import { useSelector } from "react-redux";
import { useState } from "react";
import { file_url } from "../../../service/config";
import { Fade } from "react-awesome-reveal";
import { useLanguage } from "../../../service/langue/LanguageProvider";
export default function MotMinistre() {
  const getDataREDUX = useSelector((state) => state.motmin);
  const [load, setload] = useState(true);
   const { translations } = useLanguage();
  console.log(getDataREDUX)
  return (
    <div className="" id="home">
      <ScrollToTop smooth />
   
      {getDataREDUX.nom_ministre ? (
        <div className="container col-xxl-8 col-md-8 px-lg-1">
          <div
            className={
              "row flex-lg-row-reverse align-items-center mb-3  py-4  "
            }
            style={{ overflow: "hidden" }}
          >
            <div className="d-flex justify-content-end align-items-end test_img col-12 col-sm-8 col-lg-6 mb-3">
              <div
                className="img-responsive img-responsive-21x9 card-img-top  w-100 rounded-circle"
                style={{
                  maxHeight: "400px !important",
                  minHeight: "400px",
                  background:
                    "url(" + file_url + getDataREDUX.photo_ministre + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              {/* <img
                src={file_url + getDataREDUX.photo_ministre}
                className="d-block w-100 my-4 h-100"
                alt="Ministre actuel"
                loading="lazy"
                onError={handleImageError}
                onLoad={() => setload(false)}
                style={{
                  transform: "scale(1.8) ",
                  minHeight:'260px'
                }}
              /> */}
            </div>
            <div className="col-lg-6 ">
              <blockquote class="blockquote  text-lg-start text-center">
                <header className="blockquote-header">
                  {" "}
                  <h1 className="display-6 fw-bold text-lg-start text-center">
                    {translations?.hero.motmin}
                  </h1>
                </header>
                <p className="lead my-4">{getDataREDUX.text_ministre}</p>
                <footer class="blockquote-footer">
                  {getDataREDUX.nom_ministre}
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      ) : (
        <div className="container col-xxl-8 col-md-8 px-lg-1 py-2">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-4 ">
            <div className="d-flex justify-content-center align-items-center test_img col-12 col-sm-8 col-lg-6">
              <div
                className="img-responsive img-responsive-21x9 card-img-top w-100 rounded-circle"
                style={{
                  maxHeight: "400px !important",
                  minHeight: "400px",
                  background: "url(" + image.min + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              {/* <img
                src={image.min}
                className="d-block w-100 my-4 h-100"
                alt="Bootstrap Themes"
                loading="lazy"
                style={{ transform: "scale(1.7) translate(0, 3px) " }}
              /> */}
            </div>
            <div className="col-lg-6">
              <h1 className="display-6 fw-bold ">{translations?.hero.mot}</h1>
              <blockquote class="blockquote">
                <p className="lead my-4">
                  Notre objectif est de faciliter la communication et la
                  collaboration entre les auto-écoles, les organismes
                  responsables et notre plateforme. Nous sommes conscients de
                  l'importance de votre rôle dans la formation des conducteurs
                  et dans la sécurité routière de notre pays.
                </p>
                <footer class="blockquote-footer">
                  Monsieur RAMONJAVELO Manambahoaka Valéry Fitzgerald
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
