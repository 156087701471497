/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faArrowDown,
  faArrowUp,
  faClockFour,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import image from "../../../assets/image/imgtest/image";
import { Fade, JackInTheBox } from "react-awesome-reveal";
import axios from "axios";
import { ACTU_API, file_url } from "../../../service/config";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Carousel } from "primereact/carousel";
import { Skeleton } from "primereact/skeleton";
import Carrousel from "./Carrousel";
import { useDispatch, useSelector } from "react-redux";
import useIntersectionObserver from "../../../outils/ObserverApparitionSection";
import { useLanguage } from "../../../service/langue/LanguageProvider";

export default function Actualite() {
  const {translations} = useLanguage()
 
  moment.locale("fr", {
    months:
      "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
    monthsShort:
      "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
    monthsParseExact: true,
    weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
    weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
    weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
    calendar: {
      sameDay: "[Aujourd’hui à] LT",
      nextDay: "[Demain à] LT",
      nextWeek: "dddd [à] LT",
      lastDay: "[Hier à] LT",
      lastWeek: "dddd [dernier à] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "dans %s",
      past: "il y a %s",
      s: "quelques secondes",
      m: "une minute",
      mm: "%d minutes",
      h: "une heure",
      hh: "%d heures",
      d: "un jour",
      dd: "%d jours",
      M: "un mois",
      MM: "%d mois",
      y: "un an",
      yy: "%d ans",
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? "er" : "e");
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
      return input.charAt(0) === "M";
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? "PD" : "MD";
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });

  const dispatch = useDispatch();

  const responsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const navigate = useNavigate();
  const test =
    " Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut oditaut fugit sed quia.";
  const test1 =
    " Nemo enim ipsam voluptatem quia ipsam voluptatem quia ipsam voluptatem ipsam voluptatem quia ipsam voluptatem quia  quia  voluptas sit aspernatur aut oditaut fugit sed quia.";
  const [filter, setfilter] = useState("desc");
  const [actual, setactual] = useState([]);
  const {dataRedux,load,sectionRef}  = useIntersectionObserver(ACTU_API + `?order${filter}`,'actu','add','data');
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
  ]);
  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }
  function truncate(str) {
    if (str) {
      return str.length > 10 ? str.substring(0, 150) + "..." : str;
    }
  }
  // const getACTU =  () => {
  
  //   dataRedux.length == 0 && setLoad(true)
  
     
  //     try {
  //        axios
  //         .get(ACTU_API + `?order${filter}`)
  //         .then((reponse) => {
  //           console.log(reponse);
  //           const sortedData = reponse.data.data
  //           setactual(sortedData);
  //           dispatch({type:'add', payload: sortedData})
  //           setLoad(false);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
    
   
  // };

  const skeletonTemplate = () => {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center"
        style={{ height: "400px" }}
      >
        <Skeleton
          shape="rectangular"
          width="250px"
          height="100%"
         
        />
       
      </div>
    );
  };


  
  // useEffect(() => {
  //      getACTU();
  // }, []);
  return (
    <div ref={sectionRef} className="container-fluid actualite bg-light pt-4 pb-0" id="actu">
      <div className="container-lg container-fluid-md">
        <div className="my-4 d-flex align-items-center gap-3">
         
          <JackInTheBox triggerOnce><p className="h1">{translations?.actu}</p></JackInTheBox>
          <div className="vr w-1 bg-primary"></div>
          <div className="card-actions btn-actions">
            <div className="dropdown position-relative">
              <a
                href="#"
                className="btn-action dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                </svg>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                data-popper-placement="bottom-end"
                style={{
                  position: "absolute",
                  inset: " 0px 0px auto auto",
                  margin: "0px",
                  transform: "translate3d(0px, 34.4px, 0px)",
                }}
              >
                <div
                  className="dropdown-item"
                  onClick={() => setfilter("desc")}
                >
                  <FontAwesomeIcon icon={faArrowUp} className="me-2" />
                  Déscendant
                </div>
                <div className="dropdown-item" onClick={() => setfilter("asc")}>
                  <FontAwesomeIcon icon={faArrowDown} className="me-2" />
                  Ascendant
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lg container-fluid-md">
          {load ? (
            <>
              <Carousel
                value={[null, null, null, null]}
                numVisible={4}
                numScroll={1}
                showIndicators={false}
                showNavigators={false}
                circular
                itemTemplate={skeletonTemplate}
              />
            </>
          ) : dataRedux.length === 0 ? (
            <div className="w-100  my-5 p-5">
              <p className="display-5 fw-light text-secondary text-center">
                Aucun Actualité pour le moment
              </p>
            </div>
          ) : (
            <div>
              <Carrousel type='actu' data={dataRedux.sort((a, b) => {
              return new Date(b.created_at) - new Date(a.created_at);
            })} load={load}/>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-4 mb-2">
          {load === false && actual.length > 3 ? (
            <div
              onClick={() => navigate("/tous les actualités")}
              className="Nav-link btn btn-primary rounded-0 text-decoration-none"
            >
              Plus d’actualités
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
