import React, { useEffect, useState } from "react";
import message from "../../../assets/svg/Car crash-pana.svg";
import { Bounce, Fade, Slide } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faClockFour,
} from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/image/imgtest/image";
import axios from "axios";
import { file_url, SENSI_API } from "../../../service/config";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { Skeleton } from "primereact/skeleton";


import Carrousel from "./Carrousel";
import { useDispatch, useSelector } from "react-redux";
import useIntersectionObserver from "../../../outils/ObserverApparitionSection";
import { useLanguage } from "../../../service/langue/LanguageProvider";
export default function Section4() {
  const {translations} = useLanguage()
  const dispatch = useDispatch();
  const test =
    " Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut oditaut fugit sed quia.";
  const test1 =
    " Nemo enim ipsam voluptatem quia ipsam voluptatem quia ipsam voluptatem ipsam voluptatem quia ipsam voluptatem quia  quia  voluptas sit aspernatur aut oditaut fugit sed quia.";
  function truncate(str) {
    return str.length > 10 ? str.substring(0, 150) + "..." : str;
  }
  const navigate = useNavigate();

  const [sensib, setSensib] = useState([]);
  const [filter, setfilter] = useState("asc");
  const {dataRedux,load,sectionRef}  = useIntersectionObserver(SENSI_API + `?order=${filter}`,'sensi','addsens','data');
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
  ]);
  const skeletonTemplate = () => {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center p-1"
        style={{ height: "400px" }}
      >
        <Skeleton
          shape="rectangular"
          width="250px"
          height="100%"
          style={{ marginRight: "2rem" }}
        />
      </div>
    );
  };
  // const getsensib = async () => {
  //   dataRedux.length == 0 && setload(true)
  //   try {
  //     await axios
  //       .get(SENSI_API + `?order=${filter}`)
  //       .then((reponse) => {
  //         console.log(reponse);
  //         setSensib(reponse.data.data);
  //         dispatch({type:'addsens', payload: reponse.data.data})
  //         setload(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
   
  
  // };



  return (
    <div className="container-fluid actualite bg-light pt-4 pb-0" id="actu">
      <div className="container-lg container-fluid-md" style={{overflow:'hidden'}}>
        <div className="my-4 d-flex justify-content-between align-items-center gap-3">
         
          <Slide triggerOnce >  <p className="h1">{translations?.awarenessMessage}</p></Slide>  <div className="vr w-1 bg-primary"></div>
          <div className="card-actions btn-actions">
            <div className="dropdown position-relative">
              <a
                href="#"
                className="btn-action dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                </svg>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                data-popper-placement="bottom-end"
                style={{
                  position: "absolute",
                  inset: " 0px 0px auto auto",
                  margin: "0px",
                  transform: "translate3d(0px, 34.4px, 0px)",
                }}
              >
                <div
                  className="dropdown-item"
                  onClick={() => setfilter("desc")}
                >
                  <FontAwesomeIcon icon={faArrowUp} className="me-2" />
                  Déscendant
                </div>
                <div className="dropdown-item" onClick={() => setfilter("asc")}>
                  <FontAwesomeIcon icon={faArrowDown} className="me-2" />
                  Ascendant
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
      </div>
     
        <div ref={sectionRef} className="container-lg container-fluid">
          {load ? (
            <>
              <Carousel
                value={[null, null, null, null]}
                numVisible={4}
                numScroll={1}
                itemTemplate={skeletonTemplate}
                showIndicators={false}
                showNavigators={false}
              />
            </>
          ) : dataRedux.length === 0 ? (
            <div className="w-100  my-5 p-5">
              <p className="display-5 fw-light text-secondary text-center">
                Aucun message de sensibilisation
              </p>
            </div>
          ) : (
            <div className="">
              <Carrousel type='sensi' data={dataRedux.filter((el,i)=>el.type_sens == "Public")} load={load}/>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-4 mb-2">
          {load === false && dataRedux.filter((el,i)=>el.type_sens == "Public").length > 3 ? (
            <div
              onClick={() => navigate("/tous les message de sensibilisation")}
              className="Nav-link btn btn-primary rounded-0 text-decoration-none"
            >
              {translations?.moreAwarenessMessages}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
